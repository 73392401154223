class AlternativeRegistrationEvent
{
    constructor(data) {
        this._id = data._id;
        this.name = data.name;
        this.startTime = data.startTime;
        this.startTimeFormatted = data.startTimeFormatted;
    }
}

export default  AlternativeRegistrationEvent;