<template>
    <div>
        <v-row class="mt-5 justify-end"
               justify="end"
        >
            <v-col
                v-if="canDownload"
                class="d-flex"
                style="justify-content : flex-end"
            >
                <h3 v-if="!loadingExcel"
                    class="font-weight-regular mr-3 mt-2 clickable-text"
                    @click="exportPolls"
                >
                    Download polls
                </h3>
                <h3 v-else
                    class="font-weight-regular mr-3 mt-2"
                >
                    Generating report
                </h3>
                <v-btn v-if="!loadingExcel"
                       icon
                       class="mr-5"
                       @click="exportPolls"
                >
                    <v-icon large>
                        mdi-file-download-outline
                    </v-icon>
                </v-btn>
                <v-progress-circular
                    v-else
                    indeterminate
                    class="mr-5"
                />
            </v-col>
        </v-row>
        <polls-display v-for="poll in polls"
                       :key="poll.question"
                       :poll="poll"
        />
    </div>
</template>


<script>
    import { mapGetters, mapState } from 'vuex';
    import PollsDisplay from '@/components/pages/Project/Elements/PollsDisplay';
    import {$http} from '@/services/http';
    import {STATS_POLLS_EXPORT_URL} from '@/services/ApiService';

    export default {
        name: 'PollsStatistics',
        components: {
            PollsDisplay,
        },
        props:{
            selectedEvent: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                loadingExcel: false,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapState('statistics', ['polls']),
            ...mapGetters('auth', ['canDownloadStats']),
            canDownload() {
                return this.canDownloadStats(this.$route.params.project_id);
            }
        },
        methods: {
            exportPolls() {
                this.loadingExcel = true;
                const url = STATS_POLLS_EXPORT_URL.replace('{event_id}', this.selectedEvent._id);
                $http({
                    url,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const hiddenElement = document.createElement('a');
                    hiddenElement.download = 'polls-' + this.selectedEvent._id + '.xlsx';
                    hiddenElement.href = url;
                    hiddenElement.click();
                });
                this.loadingExcel = false;
            }
        },
    };
</script>

<style scoped lang="scss">
.clickable-text:hover{
    cursor: pointer;
}
</style>
