<template>
    <div>
        <v-row class="mt-5 justify-end"
               justify="end"
        >
            <v-col
                v-if="canDownload"
                class="d-flex"
                style="justify-content : flex-end"
            >
                <h3 v-if="!loadingExcel"
                    class="font-weight-regular mr-3 mt-2 clickable-text"
                    @click="exportExcel"
                >
                    Download evaluations
                </h3>
                <h3 v-else
                    class="font-weight-regular mr-3 mt-2"
                >
                    Generating report
                </h3>
                <v-btn v-if="!loadingExcel"
                       icon
                       class="mr-5"
                       @click="exportExcel"
                >
                    <v-icon large>
                        mdi-file-download-outline
                    </v-icon>
                </v-btn>
                <v-progress-circular
                    v-else
                    indeterminate
                    class="mr-5"
                />
            </v-col>
        </v-row>
        <v-row class="mt-15">
            <v-simple-table v-if="!loading">
                <thead>
                    <tr class="evaluation-stats-header">
                        <th
                            v-for="(header, key) in evaluationHeaders"
                            :key="key"
                        >
                            <p>{{ header.text }}</p>
                        </th>
                    </tr>
                    <tr class="evaluation-stats-subheader">
                        <th
                            v-for="(count, key) in evaluationCounts"
                            :key="key"
                        >
                            <p v-if="key === 'created_at'">
                                Answer count
                            </p>
                            <p v-else>
                                {{ count }}
                            </p>
                        </th>
                    </tr>
                    <tr class="evaluation-stats-subheader">
                        <th
                            v-for="(average, key) in evaluationAverages"
                            :key="key"
                        >
                            <p v-if="key === 'created_at'">
                                Average score
                            </p>
                            <p v-else>
                                <span v-if="average > -1">{{ average }}</span>
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(answer, key) in evaluations.answers"
                        :key="key"
                    >
                        <td
                            v-for="(header, key) in evaluationHeaders"
                            :key="`${getAnswer(answer, key).registration_id}-${key}`"
                        >
                            <component
                                :is="getComponent(header.type)"
                                :value="getAnswer(answer, key).answer"
                            />
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-container fluid>
                <v-row align="center"
                       align-content="center"
                       justify="center"
                >
                    <v-col class="d-flex"
                           cols="1"
                           sm="1"
                    >
                        <small class="d-flex justify-right"
                               style="width: 100%; white-space: nowrap"
                        >
                            Rows per page
                        </small>
                    </v-col>
                    <v-col class="d-flex"
                           cols="1"
                           sm="1"
                    >
                        <v-select
                            v-model="rowsPerPageSelected"
                            :items="rowsPerPageItems"
                            :label="undefined"
                            :outlined="true"
                            :dense="true"
                            :hide-details="true"
                            @input="rowsPerPageChanged"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="d-flex justify-end"
                                      style="width: 100%;"
                                >
                                    {{ item }}
                                </span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col class="d-flex"
                           cols="6"
                           sm="3"
                    >
                        <v-pagination v-if="pagination.total > 1"
                                      v-model="currentPage"
                                      :length="pagination.total"
                                      circle
                                      total-visible="6"
                                      color="secondary"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import {
        EVALUATION_FIELD_TYPE_STAR_RATING,
        EVALUATION_FIELD_TYPE_SYSTEM_DATE,
    } from '@/services/EvaluationFieldService';
    import EvaluationDateTimeCell from '@/components/pages/Project/Elements/EvaluationDateTimeCell';
    import EvaluationSimpleCell from '@/components/pages/Project/Elements/EvaluationSimpleCell';
    import EvaluationStarRatingCell from '@/components/pages/Project/Elements/EvaluationStarRatingCell';
    import {debounce} from 'lodash';
    import {STATS_EVALUATIONS_EXPORT_URL} from '@/services/ApiService';
    import {$http} from '@/services/http';

    export default {
        name: 'EvaluationStatistics',
        props: {
            evaluationId:{
                type: String,
                default: () => '',
            }
        },
        data() {
            return {
                loading: true,
                paginatorCurrentPageKey: 1,
                rowsPerPageItems: [10, 25, 50, 100],
                rowsPerPageSelected: 10,
                loadingExcel: false
            };
        },
        components: {
            EvaluationSimpleCell,
            EvaluationStarRatingCell,
            EvaluationDateTimeCell,
        },
        watch: {
            paginatorCurrentPageKey: debounce(function () {
                this.loadData(false);
            }, 400),
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapState('evaluations', ['evaluations', 'pagination']),
            ...mapGetters('auth', ['canDownloadStats', 'canSeeRegistrationData']),
            currentPage: {
                get() {
                    return this.pagination.current;
                },
                set(value) {
                    this.paginatorCurrentPageKey += 1;
                    this.setCurrentPage(value);
                }
            },
            canDownload() {
                return this.canDownloadStats(this.$route.params.project_id);
            },
            evaluationHeaders() {
                return this.filteredKeys(this.evaluations.headers);
            },
            evaluationAverages() {
                return this.filteredKeys(this.evaluations.averages);
            },
            evaluationCounts() {
                return this.filteredKeys(this.evaluations.counts);
            }
        },
        methods: {
            ...mapActions('evaluations', ['getEvaluations']),
            ...mapMutations('evaluations', ['setCurrentPage', 'setPageTotal']),
            async loadData(reload) {
                this.loading = reload;
                await this.getEvaluations({
                    projectId: this.project?._id,
                    evaluationId: this.evaluationId,
                    params: this.getParams()
                });
                this.loading = false;
            },
            filteredKeys(objects) {
                if (this.canSeeRegistrationData(this.$route.params.project_id)) {
                    return objects;
                }

                const clonedObjects = {...objects};
                delete clonedObjects['registration_email'];
                delete clonedObjects['registration_name'];
                return clonedObjects;
            },
            getComponent(columnType) {
                switch (columnType) {
                default:
                    return 'EvaluationSimpleCell';
                case EVALUATION_FIELD_TYPE_STAR_RATING:
                    return 'EvaluationStarRatingCell';
                case EVALUATION_FIELD_TYPE_SYSTEM_DATE:
                    return 'EvaluationDateTimeCell';
                }
            },
            getAnswer(answer, key) {
                return answer[key] || {key: key, answer: ''};
            },
            rowsPerPageChanged() {
                window.localStorage.setItem('evaluationRowsPerPage', this.rowsPerPageSelected);
                this.setCurrentPage(1);
                this.loadData(true);
            },
            getParams() {
                const params = {};
                if (this.currentPage && this.currentPage > 1) {
                    params['page'] = this.currentPage;
                }
                params['perPage'] = this.rowsPerPageSelected;
                return params;
            },
            exportExcel() {
                this.loadingExcel = true;
                const url = STATS_EVALUATIONS_EXPORT_URL
                    .replace('{project_id}', this.project._id)
                    .replace('{evaluation_id}', this.evaluationId);
                $http({
                    url,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const hiddenElement = document.createElement('a');
                    hiddenElement.download = 'evaluations-' + this.project?._id + '.xlsx';
                    hiddenElement.href = url;
                    hiddenElement.click();
                });
                this.loadingExcel = false;
            }
        },
        mounted() {
            let rowsPerPage = window.localStorage.getItem('evaluationRowsPerPage');
            if (rowsPerPage) {
                this.rowsPerPageSelected = parseInt(rowsPerPage);
            }
            this.setCurrentPage(1);
            this.loadData(true);
        }
    };
</script>

<style scoped lang="scss">
    .evaluation-stats-header th {
        background-color: #d6d6d6;
        border-top: thin solid rgba(0,0,0,.12);
        border-bottom: thin solid rgba(0,0,0,.12);
    }
    .evaluation-stats-subheader {
        background-color: #f5f5f5;
    }
    .evaluation-stats-subheader th{
        border-bottom: thin solid rgba(0,0,0,.12);
    }
    .clickable-text:hover{
        cursor: pointer;
    }
</style>
