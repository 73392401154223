<template>
    <v-dialog v-model="dialog"
              width="700"
              @click:outside="cancel"
    >
        <v-card>
            <v-card-title>Crop image</v-card-title>
            <v-card-text>
                <vue-cropper
                    ref="cropper"
                    :src="selectedFile"
                    :auto-crop-area="1"
                    :view-mode="2"
                    :img-style="{ 'max-width': '500px', 'max-height': '500px' }"
                    alt="Source Image"
                />
            </v-card-text>
            <v-card-actions class="px-5 pb-5">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            class="mr-2"
                            v-on="on"
                            @click.prevent="zoom(0.2)"
                        >
                            <v-icon color="primary">
                                mdi-magnify-plus-outline
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Zoom in</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            class="mr-2"
                            v-on="on"
                            @click.prevent="zoom(-0.2)"
                        >
                            <v-icon color="primary">
                                mdi-magnify-minus-outline
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Zoom out</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            class="mr-2"
                            v-on="on"
                            @click.prevent="rotate(-90)"
                        >
                            <v-icon color="primary">
                                mdi-rotate-left
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Rotate left</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            class="mr-2"
                            v-on="on"
                            @click.prevent="rotate(90)"
                        >
                            <v-icon color="primary">
                                mdi-rotate-right
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Rotate right</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            ref="flipY"
                            icon
                            class="mr-2"
                            v-on="on"
                            @click.prevent="flipY"
                        >
                            <v-icon color="primary">
                                mdi-flip-vertical
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Flip vertical</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            ref="flipX"
                            icon
                            class="mr-2"
                            v-on="on"
                            @click.prevent="flipX"
                        >
                            <v-icon color="primary">
                                mdi-flip-horizontal
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Flip horizontal</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mr-2"
                            outlined
                            color="primary"
                            small
                            v-on="on"
                            @click.prevent="setAspectRatio(1/1)"
                        >
                            1:1
                        </v-btn>
                    </template>
                    <span>1:1 aspect ratio</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mr-2"
                            outlined
                            color="primary"
                            small
                            v-on="on"
                            @click.prevent="setAspectRatio(4/3)"
                        >
                            4:3
                        </v-btn>
                    </template>
                    <span>4:3 aspect ratio</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mr-2"
                            outlined
                            color="primary"
                            small
                            v-on="on"

                            @click.prevent="setAspectRatio(16/9)"
                        >
                            16:9
                        </v-btn>
                    </template>
                    <span>16:9 aspect ratio</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            class="mr-2"
                            v-on="on"
                            @click.prevent="reset"
                        >
                            <v-icon color="primary">
                                mdi-restore
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Reset image</span>
                </v-tooltip>
                <v-spacer />
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn 
                            class="mr-2 primary"
                            text
                            v-on="on"
                            @click="saveImage(), (dialog = false)"
                        >
                            Save
                        </v-btn>
                    </template>
                    <span>Crop and save</span>
                </v-tooltip>
                <v-btn
                    @click="cancel"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import 'cropperjs/dist/cropper.css';
    import VueCropper from 'vue-cropperjs';
    export default {
        name: 'ImageCropper',
        components: {VueCropper},
        props: ['file'],
        data(){
            return { 
                mime_type: '',
                cropedImage: '',
                autoCrop: false,
                selectedFile: '',
                image: '',
                dialog: false,
                files: '',
                scaleX: -1,
                scaleY: -1,
            };
        },
        methods:{
            saveImage() {
                this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
                this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
                    this.$emit('save', blob);
                }, this.mime_type);
                this.dialog = false;
            },
            rotate(deg) {
                this.$refs.cropper.rotate(deg);
            },
            flipX() {
                this.$refs.cropper.scaleX(this.scaleX);
                this.scaleX = -this.scaleX;
            },
            flipY() {
                this.$refs.cropper.scaleY(this.scaleY);
                this.scaleY = -this.scaleY;
            },
            zoom(percent) {
                this.$refs.cropper.relativeZoom(percent);
            },
            reset() {
                this.$refs.cropper.reset();
                this.setAspectRatio(0);
            },
            cancel(){
                this.dialog = false;
                this.$emit('cancel');
            },
            setAspectRatio(value){
                this.$refs.cropper.setAspectRatio(value);

            }
        },
        watch:{
            file(value){
                if(value){
                    this.dialog = true;
                    this.scaleX = -1;
                    this.scaleY = -1;
                    const file = value;
                    this.mime_type = file.type;
                    if (typeof FileReader === 'function') {
                        this.dialog = true;
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            this.selectedFile = event.target.result;
                            this.$refs.cropper.replace(event.target.result);
                        };
                        reader.readAsDataURL(file);
                    } else {
                        alert('Sorry, FileReader API not supported');
                    }
                }
            }
        }
    };
</script>