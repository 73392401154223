<template>
    <div>
        <h3>Alternative events for registration</h3>
        <v-divider class="mb-10" />
        <v-alert type="info"
                 outlined
        >
            Alternative Events allow registrants to select a different event (often at different time) when registering.
        </v-alert>
        <div class="d-flex align-center">
            <div class="mr-4 d-inline-block">
                Enable registrants to select an alternative event when registering.
            </div>
            <v-switch
                v-model="enabled"
                inset
                :disabled="project.isArchived"
            />
        </div>
        <v-text-field v-if="enabled"
                      v-model="labelText"
                      label="Label text"
        >
            <template v-slot:append-outer>
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-btn icon
                               v-on="on"
                               @click="setDefaultLabelText"
                        >
                            <v-icon>
                                refresh
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Reset to default</span>
                </v-tooltip>
            </template>
        </v-text-field>
        <upcoming-events-editor v-if="enabled"
                                v-model="events"
        />
    </div>
</template>
<script>
    import { mapActions, mapMutations, mapState } from 'vuex';
    import UpcomingEventsEditor from '@/components/controls/upcoming-events/UpcomingEventsEditor.vue';
    import AlternativeRegistrationEvent from '@/models/AlternativeRegistrationEvent';

    export default {
        name: 'AlternativeEventsEditor',
        components: {UpcomingEventsEditor},
        computed: {
            ...mapState('projects', ['project']),
            enabled: {
                get() {
                    return this.project.alternativeRegistrationEvents.enabled;
                },
                set(value) {
                    this.setAlternativeRegistrationEventsEnabled(value);
                    if (value === true && !this.labelText) {
                        this.setDefaultLabelText();
                    }
                    this.$nextTick(() => {
                        this.saveProjectImmediate({
                            key: 'alternativeRegistrationEvents',
                            value: this.project.alternativeRegistrationEvents
                        });
                    });
                }
            },
            labelText: {
                get() {
                    return this.project.alternativeRegistrationEvents.labelText;
                },
                set(value) {
                    this.setAlternativeRegistrationEventsLabelText(value);
                    this.$nextTick(() => {
                        this.saveProject({
                            key: 'alternativeRegistrationEvents',
                            value: this.project.alternativeRegistrationEvents
                        });
                    });
                }
            },
            events: {
                get() {
                    return this.project.alternativeRegistrationEvents.events;
                },
                set(value) {
                    this.setAlternativeRegistrationEventsEvents((value).map(e => {
                        return new AlternativeRegistrationEvent({
                            _id: e._id,
                            name: e.name,
                            startTime: e.startTime,
                            startTimeFormatted: e.startTimeFormatted
                        });
                    }));
                    this.$nextTick(() => {
                        this.saveProjectImmediate({
                            key: 'alternativeRegistrationEvents',
                            value: this.project.alternativeRegistrationEvents
                        });
                    });
                }
            }
        },
        methods: {
            ...mapMutations('projects', [
                'setAlternativeRegistrationEventsEnabled',
                'setAlternativeRegistrationEventsEvents',
                'setAlternativeRegistrationEventsLabelText',
            ]),
            ...mapActions('projects', ['saveProjectImmediate', 'saveProject']),
            setDefaultLabelText() {
                let labelText;
                switch (this.project.language) {
                case 'nlInformal':
                    labelText = 'Selecteer het evenement waaraan je wil deelnemen:';
                    break;
                case 'nlFormal':
                    labelText = 'Selecteer het evenement waaraan u wil deelnemen:';
                    break;
                default:
                    labelText = 'Please select the event you want to participate on:';
                    break;
                }
                this.labelText = labelText;
            }
        }
    };
</script>

<style scoped>

</style>