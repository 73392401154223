<template>
    <div>
        <label style="font-size: 16px">
            <strong>Font family</strong>
        </label>
        <v-select
            v-model="fontFamily"
            :items="fonts"
            item-text="label"
            item-value="value"
            class="mt-0"
            hide-details
            :disabled="project.isArchived"
        >
            <template v-slot:selection="{item}">
                <v-list-item-content>
                    <v-list-item-title :style="{'font-family': item.label}">
                        {{ item.label }}
                    </v-list-item-title>
                </v-list-item-content>
            </template>
            <template v-slot:item="{item}">
                <v-list-item-content>
                    <v-list-item-title :style="{'font-family': item.label}">
                        {{ item.label }}
                    </v-list-item-title>
                </v-list-item-content>
            </template>
        </v-select>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';
    export default {
        name: 'FontSelector',
        data(){
            return{
                fonts: [{
                    label:'Archivo',
                    value: 'archivo'
                },{
                    label:'Open Sans',
                    value: 'open-sans'
                },{
                    label:'Roboto',
                    value: 'roboto'
                },{
                    label:'Lato',
                    value: 'lato'
                },{
                    label:'Montserrat',
                    value: 'montserrat'
                },{
                    label:'Oswald',
                    value: 'oswald'
                },{
                    label:'Source Sans Pro',
                    value: 'source-sans-pro'
                },{
                    label:'Slabo 27px',
                    value: 'slabo'
                },{
                    label:'Raleway',
                    value: 'raleway'
                },{
                    label:'PT Sans',
                    value: 'pt-sans'
                },{
                    label:'Merriweather',
                    value: 'merriweather'
                },{
                    label: 'GalanoGrotesque',
                    value: 'GalanoGrotesque'
                },{
                    label: 'Caros',
                    value: 'Caros'
                },{
                    label: 'Arial',
                    value: 'arial'
                },{
                    label: 'MyriadForRabobank',
                    value: 'MyriadForRabobank',
                }],
            };
        },
        computed:{
            ...mapState('projects', ['project']),
            fontFamily: {
                get(){
                    return this.project.style.fontFamily;
                },
                set(value){
                    this.setFontFamily(value);
                    this.saveProject({
                        key: 'style.fontFamily',
                        value,
                    });
                }
            },
        },
        methods: {
            ...mapActions('projects', ['saveProject']),
            ...mapMutations('projects', ['setFontFamily'])
        }
    };
</script>
