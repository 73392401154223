import AlternativeRegistrationEvent from '@/models/AlternativeRegistrationEvent';

class AlternativeRegistrationEvents {
    constructor(data) {
        this.enabled = data?.enabled ?? false;
        const events = data?.events ?? [];
        this.labelText = data?.labelText ?? '';
        this.events = events.map(e => new AlternativeRegistrationEvent(e));
    }
}

export default AlternativeRegistrationEvents;