<template>
    <div>
        <div v-if="(showImage && !value.url) || (!showImage)"
             class="d-flex align-baseline"
        >
            <v-file-input
                :key="imageKey"
                ref="imageInput"
                :label="label"
                accept="image/jpg, image/jpeg, image/png, *.jpeg, *.jpg, *.png"
                truncate-length="50"
                show-size
                :rules="rules"
                :disabled="project.isArchived"
                prepend-icon=""
                append-icon="mdi-image-plus"
                class="file-input pr-2"
                @click:append="$refs.imageInput.$el.querySelector('input').click()"
                @change="onFileSelect"
            />
            <slot name="hint" />
        </div>
        <div v-else
             class="d-flex mb-2"
        >
            <label class="font-weight-regular pt-2">
                {{ label }}
            </label>
            <slot name="hint" />
        </div>
        <image-cropper :file="selectedFile"
                       @save="uploadBackground"
                       @cancel="imageKey++"
        />
        <div v-if="!imageUploading"
             class="pl-5"
        >
            <img
                v-if="value.url && showImage"
                :src="value.url"
                class="image-picker-image"
            >
            <v-btn
                v-if="value.url && showImage"
                icon
                color="primary"
                class="mt-n2"
                :disabled="project.isArchived"
                @click="removeImage"
            >
                <v-icon>
                    mdi-image-remove
                </v-icon>
            </v-btn>
        </div>
        <v-progress-circular v-else
                             indeterminate
                             color="primary"
        />
        <v-divider v-if="value.url || imageUploading"
                   class="my-2"
        />
    </div>
</template>

<script>
    import ImageCropper from '@/components/controls/ImageCropper';
    import { $http } from '@/services/http';
    import { mapState } from 'vuex';

    export default {
        name: 'ImagePricker',
        components:{ImageCropper},
        props:{
            value:{
                type: Object,
                required: true,
            },
            label: {
                type: String,
                default: () => '',
            },
            rules: {
                type: Array,
                default: () => [],
            },
            uploadUrl: {
                type: String,
                default: () => '',
            },
            maxFileSize: {
                type: Number,
                default: () => 0,
            },
            formDataKey: {
                type: String,
                default: 'image'
            },
            showImage: {
                type: Boolean,
                default: () => true
            }
        },
        data(){
            return {
                imageKey: 0,
                imageUploading: false,
                selectedFile:'',
            };
        },
        computed:{
            ...mapState('projects', ['project']),
        },
        methods: {
            async uploadBackground(file) {
                if (file === undefined || file.size > this.maxFileSize) {
                    return Promise.reject();
                }
                const formData = new FormData();
                formData.append(`${this.formDataKey}`, file);
                this.imageUploading = true;
                return $http.post(this.uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then((res)=>{
                    this.$emit('input', {'url': res.data.url, 'key': res.data.key});
                }).finally(()=>{
                    this.imageUploading = false;
                    this.imageKey++;
                });  
            },
            removeImage(){
                this.$emit('input', {'url': '', 'key': ''});
                this.imageKey++;
            },
            onFileSelect(e){
                this.selectedFile = e;
            }
        }
    };
</script>

<style lang="scss">
.file-input{
    .v-icon.v-icon.v-icon--link{
        padding-top: 10px;
        color: #2977BE
    }
}
.image-picker-image{
    max-width: 50%
}
</style>