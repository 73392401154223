import Registration from '@/models/Registration';
import Style from '@/models/Style';
import PwaStyle from '@/models/PwaStyle';
import RegistrationConfirmationEmailSettings from '@/models/RegistrationConfirmationEmailSettings';
import ReminderEmailSettings from '@/models/ReminderEmailSettings';
import PostEventEmailSettings from '@/models/PostEventEmailSettings';
import ThankYouEmailSettings from '@/models/ThankYouEmailSettings';
import ExtraEmailSettings from '@/models/ExtraEmailSettings';
import OnSiteViewerReminderEmailSettings from '@/models/OnSiteViewerReminderEmailSettings';
import Evaluation from './Evaluation';
import Company from '@/models/Company';
import Security from '@/models/Security';
import Speaker from '@/models/Speaker';
import EndPage from '@/models/EndPage';
import TimeService from '@/services/TimeService';
import {get} from 'lodash';
import DynamicBlocks from '@/models/DynamicBlocks';
import OnSiteRegistrationConfirmationEmailSettings from '@/models/OnSiteRegistrationConfirmationEmailSettings';
import Location from '@/models/Location';
import CustomCtaButton from '@/models/CustomCtaButton';
import AlternativeRegistrationEvents from '@/models/AlternativeRegistrationEvents';

class Project {
    constructor(data) {
        this._id = data?._id || '';
        this.name = data?.name || '';
        this.events = data?.events || [];
        this.evaluation = new Evaluation(data?.evaluation || {
            fields: [],
            _id: '',
        });
        this.onSiteEvaluation = new Evaluation(data?.onSiteEvaluation || {
            fields: [],
            _id: '',
        });
        this.language = data?.language || 'nlFormal';
        this.type = data?.type || 'singleSession';
        this.template = data?.template || 'default';
        this.registration = new Registration(data?.registration || {
            fields: [
                {
                    name: 'email',
                    type: 'email',
                    required: true,
                    label: 'E-mail address'
                }
            ]
        });
        this.extraMailEnabled = data?.extraMailEnabled || false;
        this.style = new Style(data?.style || {});
        this.pwaStyle = new PwaStyle(data?.pwaStyle || {});

        const enableMailings = data?.emailSettings?.enableMailings;

        this.emailSettings = {
            mailFromName: data?.emailSettings?.mailFromName || '',
            salutation: data?.emailSettings?.salutation || '',
            footerText: data?.emailSettings?.footerText || '',
            nameSetup: data?.emailSettings?.nameSetup || '',
            registrationConfirmation: new RegistrationConfirmationEmailSettings(data?.emailSettings?.registrationConfirmation || {}),
            onSiteRegistrationConfirmation: new OnSiteRegistrationConfirmationEmailSettings(data?.emailSettings?.onSiteRegistrationConfirmation || {}),
            reminder: new ReminderEmailSettings(data?.emailSettings?.reminder || {}),
            postEvent: new PostEventEmailSettings(data?.emailSettings?.postEvent || {}),
            thankYou: new ThankYouEmailSettings(data?.emailSettings?.thankYou || {}),
            extraMail: new ExtraEmailSettings(data?.emailSettings?.extraMail || {}),
            onSiteViewerReminder: new OnSiteViewerReminderEmailSettings(data?.emailSettings?.onSiteViewerReminder || {}),
            headerImg: data?.emailSettings?.headerImg || '',
            headerImgUrl: data?.emailSettings?.headerImgUrl || '',
            enableMailings: typeof enableMailings !== 'undefined' && enableMailings !== null ? enableMailings : true ,
        };
        this.scheduledEmails = data?.scheduledEmails || [];
        this.companyId = data?.companyId || '';
        this.company = new Company(data?.company || []);
        this.security = new Security(data?.security || {});
        this.isArchived = get(data,'archived_at', null) !== null;
        this.archivedAt =  this.isArchived ? TimeService.dateFromUnixTimestamp(data.archived_at) : null;
        this.isPseudonymized = get(data, 'pseudonymized_at', null) !== null;
        this.privacyPolicyUrl = data?.privacyPolicyUrl || '';
        this.splashPageContent = data?.splashPageContent || '';
        this.endPage = new EndPage(data?.endPage || []);
        this.startTime = data?.startTime ? data.startTime : 0;
        this.showPollsOverVideo = data?.showPollsOverVideo || false;
        this.showQuizOverVideo = data?.showQuizOverVideo || false;
        this.eventTitle = data?.eventTitle || '';
        this.customTab = data?.customTab || {
            show: false,
            title: '',
            bodyText: '',
            icon: ''
        };
        this.evaluationButton = data?.evaluationButton || {
            title: false,
            href: '',
            overwrite: '',
        };
        this.elearningButton = data?.elearningButton || {
            title: false,
            href: '',
        };
        this.speakers = data?.speakers ?  data.speakers.map(s => new Speaker(s)) : [];
        this.hideTimezone = data?.hideTimezone || false;
        this.hideCalendarInvite = data?.hideCalendarInvite || false;
        this.preventSwitchingRooms = data?.preventSwitchingRooms || false;
        this.enableGeneratedRegistrationStatistics = data?.enableGeneratedRegistrationStatistics || false;
        this.dynamicBlocks = new DynamicBlocks(data?.dynamicBlocks || {});
        this.waitingPageDynamicBlocks = new DynamicBlocks(data?.waitingPageDynamicBlocks || {});
        this.topics = data?.topics ? data.topics : [];
        this.expectedOnSiteViewers = data?.expectedOnSiteViewers;
        this.location = new Location(data?.location || {});
        this.onSiteStartTime = data?.onSiteStartTime ? data.onSiteStartTime : 0;
        this.pwaHomescreenContent = data?.pwaHomescreenContent || '';
        this.calendarTitle = data?.calendarTitle || '';
        this.showProgramTab = get(data, 'showProgramTab', false);
        this.requireRegistrationForOnDemand = get(data, 'requireRegistrationForOnDemand', false);
        this.customStyle = get(data, 'customStyle', '');
        this.customScript = get(data, 'customScript', '');
        this.customCtaButton = new CustomCtaButton(get(data, 'customCtaButton', null));
        this.alternativeRegistrationEvents = new AlternativeRegistrationEvents(get(data, 'alternativeRegistrationEvents', null));
    }
}

export default Project;
